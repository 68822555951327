import React from "react";
import SignupMailPresenter from "./SIgnupMailPresenter";
import { useLocation } from "react-router-dom";

const SignupMailContainer = () => {

  return <SignupMailPresenter />;
};

export default SignupMailContainer;
